import {EtatSaisieGroupe, Groupe, determinerGroupeSelectionne} from "./EtatSaisieGroupe";
import {BornesSaisieDate, getBornesEtat} from "./BornesSaisieDate";
import {insertAt, replaceAt} from "../utils";
import {DateTime} from "luxon/src/datetime";

/**
 * Etat de saisie d'une date cohérente (jour, mois, année) dans un champ de saisie de date.
 */
export interface EtatSaisieDateCoherent {
  kind: "coherent";
  jour: EtatSaisieGroupe;
  mois: EtatSaisieGroupe;
  annee: EtatSaisieGroupe;
}

/**
 * Etat de saisie d'une date incohérente dans le champ de saisie de date.
 * Il s'agit des cas où l'on n'arrive pas à parser la valeur actuellement saisie
 */
export interface EtatSaisieDateIncoherent {
  kind: "incoherent";
  valeur: string;
}

/**
 * Etat de saisie d'une date dans un champ de saisie de date.
 */
export type EtatSaisieDate = EtatSaisieDateCoherent | EtatSaisieDateIncoherent;

/**
 * Cette fonction initialise un objet de type EtatSaisieDateCoherent représentant la saisie d'une date cohérente.
 * Elle initialise les groupes de caractères jour, mois et année à vide.
 *
 * @returns Un objet de type EtatSaisieDateCoherent initialisé.
 */
export function initialiserEtatSaisieDateCoherentVide(): EtatSaisieDateCoherent {
  return {
    kind: "coherent",
    jour: {kind: "vide"},
    mois: {kind: "vide"},
    annee: {kind: "vide"}
  };
}

/**
 * Cette fonction prend en entrée un objet 'etat' de type 'EtatSaisieDate' représentant la saisie d'une date.
 * Elle formate cette date en fonction des données de l'objet 'etat' et renvoie une chaîne de caractères
 * représentant la date formatée.
 *
 * @param etat - L'objet de type EtatSaisieDate contenant les groupes de caractères jour, mois et année.
 *
 * @returns Une chaîne de caractères représentant la date formatée ou une chaîne vide si aucun groupe n'est saisi.
 */
export function formaterEtatSaisieDatePourAffichage(etat: EtatSaisieDateCoherent) {
  const {jour, mois, annee} = etat;
  const groupesOrdonnes = [jour, mois, annee];

  if (groupesOrdonnes.every((c) => c.kind == "vide")) return "";

  const nombreGroupesNonVides = groupesOrdonnes.filter((c) => c.kind != "vide").length;

  return groupesOrdonnes
    .map((e, i) => {
      // Si un groupe est vide, on retourne "  " pour les parties jour et mois, et "    " pour l'année.
      if (e.kind == "vide") return i==nombreGroupesNonVides ? '':null
      return e.valeur;
    })
    .filter(f => f != null)
    .join("/");

}

/**
 * Calcule de l'état de saisie correspondant à la date fournie
 * La date Luxon peut être une date valide ou non, voir une null
 * @param date date Luxon
 */
export function calculerCoherenceLuxonDate(date: DateTime | null): EtatSaisieDate {
  // Si une date n'existe pas (32/05/1993) notre systeme fonctionnerai, mais on le flag en incohérent.
  // Soit il faut valider le pattern sans moment et continuer la gestion textuel, soit il faut
  if (date?.isValid) {
    return {
      kind: "coherent",
      jour: {kind: "complet", valeur: date!.toFormat("dd")},
      mois: {kind: "complet", valeur: date!.toFormat("MM")},
      annee: {kind: "complet", valeur: date!.toFormat("yyyy")}
    };
  } else if (date === null) {
    return initialiserEtatSaisieDateCoherentVide();
  } else {
    return {
      kind: "incoherent",
      valeur: date.toFormat("dd/MM/yyyy")
    };
  }
}

/**
 * Calcul le prochain état de saisie, notamment la position où le curseur devra aller en fonction de l'état précédent, et de la saisie effectuée
 * @param etat état précédent
 * @param positionDebutCurseur position du curseur ou du début de la sélection après la saisie
 * @param positionFinCurseur position de fin de la sélection après la saisie
 * @param caractereSaisi caractère saisi. Un seul caractère autorisé, ou null pour spécifier une suppression
 * @param inputType valeur du InputEvent.inputType. Utilisé pour gérer certains edge cases (suppression "en avant" notamment via l'appui sur la touche Suppr.)
 */
export function calculerEtatEtPosition(
  etat: EtatSaisieDateCoherent,
  positionDebutCurseur: number,
  positionFinCurseur: number,
  caractereSaisi: string | null,
  inputType: string,
): [EtatSaisieDate, number] {
  //On vérifie qu'il n'y a qu'un seul caractère saisi
  if ((caractereSaisi?.length ?? 0) > 1) {
    return [{kind: "incoherent", valeur: caractereSaisi!}, 0];
  }

  //On détermine la valeur textuelle précédente, et les bornes de l'état
  const valeurTextuellePrecedente = formaterEtatSaisieDatePourAffichage(etat);

  const bornes = getBornesEtat(etat);

  // Gestion lors de la selection d'une partie de la date
  if (positionDebutCurseur != positionFinCurseur) {
    const [nouvelEtat, nouvellePosition] = gererSelectionCaracteres(
      etat,
      positionDebutCurseur,
      positionFinCurseur,
      bornes!
    );

    etat = nouvelEtat;
    positionDebutCurseur = nouvellePosition;

    // En cas de suppression lors de la selection on replace le curseur au début du groupe supprimé, sinon on laisse la suite se faire.
    if (caractereSaisi == null) {
      return [etat, positionDebutCurseur];
    }
  }

  const groupeSelectionne = determinerGroupeSelectionne(valeurTextuellePrecedente, positionDebutCurseur);
  const indexFinEtatCourant = bornes?.[groupeSelectionne].indexFin ?? 0;
  const indexDebutEtatCourant = bornes?.[groupeSelectionne].indexDebut ?? 0;

  if (caractereSaisi != null) {
    const [nouvelEtat, newPosition] = gererAjoutCaractere(
      etat,
      positionDebutCurseur,
      caractereSaisi,
      groupeSelectionne,
      indexFinEtatCourant,
      bornes
    );
    etat = nouvelEtat;
    positionDebutCurseur = newPosition;
  } else {
    const [nouvelEtat, newPosition] = gererSupressionCaractere(
      etat,
      positionDebutCurseur,
      groupeSelectionne,
      indexDebutEtatCourant,
      bornes,
      inputType
    );
    etat = nouvelEtat;
    positionDebutCurseur = newPosition;
  }
  return [etat, positionDebutCurseur];
}

function gererSelectionCaracteres(
  etat: EtatSaisieDateCoherent,
  positionDebutCurseur: number,
  positionFinCurseur: number,
  bornes: BornesSaisieDate
): [EtatSaisieDateCoherent, number] {
  if (positionDebutCurseur != positionFinCurseur) {
    const groupeMapping = {
      jour: bornes.jour,
      mois: bornes.mois,
      annee: bornes.annee
    };
    //On cherche dans l'objet groupeMapping le groupe qui correspond à la selection
    const groupeModifie = Object.keys(groupeMapping).find((groupe) => {
      const borne = groupeMapping[groupe as keyof typeof groupeMapping];

      return borne.indexDebut === positionDebutCurseur && borne.indexFin + 1 === positionFinCurseur;
    }) as keyof typeof groupeMapping | undefined;


    // Si on a trouvé un groupe été selectionné parfaitement, on le vide
    if (groupeModifie) {
      etat = {...etat, [groupeModifie]: {kind: "vide"}};
      positionDebutCurseur = positionFinCurseur = groupeMapping[groupeModifie].indexDebut;
    } else {
      //TODO : gérer une sélection incomplète
      etat = initialiserEtatSaisieDateCoherentVide();
    }
  }
  return [etat, positionDebutCurseur];
}

export function fillValeur(valeur: string, groupeModifie: Groupe) {
  if(groupeModifie === "annee") {
    if(valeur.length === 1) {
      return "202"+valeur;
    }
    if(valeur.length === 2) {
      return "20"+valeur;
    }
    if(valeur.length === 3) {
      return "2"+valeur;
    }
    return valeur.substring(0,4);
  }
  else{
    if(valeur.length === 1) {
      return "0"+valeur;
    }
    return valeur.substring(0,2);
  }
}

function gererAjoutCaractere(
  etat: EtatSaisieDateCoherent,
  positionDebutCurseur: number,
  caractereSaisi: string,
  groupeSelectionne: Groupe,
  indexFinEtatCourant: number,
  bornes: BornesSaisieDate | null
): [EtatSaisieDateCoherent, number] {
  const groupeModifie: Groupe =
    etat[groupeSelectionne].kind === "complet"
      ? positionDebutCurseur <= indexFinEtatCourant
        ? groupeSelectionne
        : groupeSelectionne === "jour"
          ? "mois"
          : "annee"
      : groupeSelectionne;

  if (groupeModifie !== groupeSelectionne) {
    positionDebutCurseur += 1;

    //Si l'on tente de modifier un autre groupe que celui où le curseur est positionné, c'est que l'on était à gauche d'un slash, et qu'on a saisi qq chose
    //Si l'utilisateur a saisi lui-même un slash, on se permet de juste décaler le curseur d'une case à droite
    if (caractereSaisi == '/' || caractereSaisi == '-' || caractereSaisi == '\\') {
      return [etat, positionDebutCurseur];
    }
  }

  //Si l'utilisateur a saisi un slash, il tente de terminer le groupe actuel
  if (caractereSaisi == '/' || caractereSaisi == '-' || caractereSaisi == '\\') {
    const etatModifie = etat[groupeModifie];

    if (etatModifie.kind === "incomplet") {
      const nouvelEtat: EtatSaisieDate = {
        ...etat,
        [groupeModifie]: {
          kind: "complet",
          valeur: fillValeur(etatModifie.valeur, groupeModifie)
        }
      };
      return [nouvelEtat, positionDebutCurseur + 1];
    }
    //sinon, on laisse l'utilisateur à la même place
    return [etat, positionDebutCurseur];
  }


  const etatModifie = etat[groupeModifie];
  const indexDebutEtatModifie = bornes?.[groupeModifie]?.indexDebut || 0;

  if (etatModifie.kind === "vide") {
    const nouvelEtat: EtatSaisieDate = {
      ...etat,
      [groupeModifie]: {
        kind: "incomplet",
        valeur: caractereSaisi
      }
    };
    return [nouvelEtat, positionDebutCurseur + 1];
  } else if (etatModifie.kind === "incomplet") {
    const positionCurseurDansGroupeCourant = positionDebutCurseur - indexDebutEtatModifie;
    const nouvelleValeur = insertAt(etatModifie.valeur, positionCurseurDansGroupeCourant, caractereSaisi);
    const nouvelEtat: EtatSaisieDate = {
      ...etat,
      [groupeModifie]: {
        kind: groupeModifie === "annee" && nouvelleValeur.length < 4 ? "incomplet" : "complet",
        valeur: nouvelleValeur
      }
    };
    return [nouvelEtat, positionDebutCurseur + 1];
  } else {
    const positionCurseurDansGroupeCourant = positionDebutCurseur - indexDebutEtatModifie;
    const nouvelleValeur = replaceAt(etatModifie.valeur, positionCurseurDansGroupeCourant, caractereSaisi);
    const nouvelEtat: EtatSaisieDate = {
      ...etat,
      [groupeModifie]: {
        kind: "complet",
        valeur: nouvelleValeur
      }
    };
    const newPosition = indexDebutEtatModifie + Math.min(positionCurseurDansGroupeCourant + 1, nouvelleValeur.length);
    return [nouvelEtat, newPosition];
  }
}


function gererSupressionCaractere(
  etat: EtatSaisieDateCoherent,
  positionDebutCurseur: number,
  groupeSelectionne: Groupe,
  indexDebutEtatCourant: number,
  bornes: BornesSaisieDate | null,
  inputType: string
): [EtatSaisieDateCoherent, number] {
  if (inputType === "deleteContentForward") {
    // Si la touche "Suppr" est pressée, déplacer le curseur vers l'avant.
    positionDebutCurseur++;
  }

  const etatModifie =
    positionDebutCurseur > indexDebutEtatCourant
      ? etat[groupeSelectionne]
      : groupeSelectionne === "annee"
        ? etat.mois
        : etat.jour;

  if (etatModifie.kind !== "vide") {
    const groupeModifie: Groupe =
      positionDebutCurseur === indexDebutEtatCourant
        ? groupeSelectionne === "annee"
          ? "mois"
          : "jour"
        : groupeSelectionne;

    if (groupeModifie !== groupeSelectionne) positionDebutCurseur -= 1;

    const indexDebutEtatModifie = bornes?.[groupeModifie].indexDebut || 0;
    const positionCurseurDansGroupeCourant = positionDebutCurseur - indexDebutEtatModifie;

    if (positionCurseurDansGroupeCourant > 0) {
      const nouvelleValeur =
        etatModifie.valeur.substring(0, positionCurseurDansGroupeCourant - 1) +
        etatModifie.valeur.substring(positionCurseurDansGroupeCourant);

      const nouvelEtat: EtatSaisieDate = {
        ...etat,
        [groupeModifie]: {
          kind: nouvelleValeur.length === 0 ? "vide" : "incomplet",
          valeur: nouvelleValeur
        }
      };
      return [nouvelEtat, positionDebutCurseur - 1];
    }
  } else if (inputType === "deleteContentForward") {
    // Si le groupe actuel est vide et la touche "Suppr" est pressée, passe au groupe suivant.
    const prochainGroupe: Groupe = groupeSelectionne === "jour" ? "mois" : "annee";
    const prochainIndexDebut = bornes?.[prochainGroupe].indexDebut || 0;
    return [etat, prochainIndexDebut];
  }
  return [etat, positionDebutCurseur];
}

