import {delay, MonoTypeOperatorFunction, pipe, tap} from "rxjs";
import {Injectable} from "@angular/core";

export type ScrollTarget = { kind: 'selecteur', selecteur: string } | { kind: 'top' };
export function scrollToSelecteur(selecteur: string): ScrollTarget {
  return {kind: 'selecteur', selecteur};
}
export function scrollToTop(): ScrollTarget {
  return {kind: 'top'};
}


@Injectable({providedIn:'root'})
export class ScrollToTargetService{
  scrollToTarget(): MonoTypeOperatorFunction<ScrollTarget>{
    return pipe(
      delay(0),
      tap((scrollTarget: ScrollTarget) => {
        const footerHeight = (document.querySelector('.footer-legal') as HTMLElement).getBoundingClientRect().height
          +(document.querySelector('.way-helpmobile-cta') as HTMLElement).getBoundingClientRect().height;

        if (scrollTarget.kind === 'selecteur') {
          const element = document.querySelector(scrollTarget.selecteur) as HTMLElement;
          if (element) {
            //Get element bouding box in the viewport
            const bounding = element.getBoundingClientRect();
            //If the element is not visible, scroll to it
            if (bounding.bottom > ((window.innerHeight-footerHeight) || (document.documentElement.clientHeight-footerHeight))) {
              const delta = bounding.bottom - ((window.innerHeight-footerHeight) || (document.documentElement.clientHeight-footerHeight));
              window.scrollTo({top: window.scrollY + delta-140, behavior: 'smooth'});
            }
          }
        } else {
          window.scrollTo({top: 0, behavior: 'smooth'});
        }
      })
    )
  }
}
