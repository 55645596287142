/**
 * Cette fonction insère la 'newStr' dans la chaîne 'str' à l'index spécifié.
 *
 * @param str - La chaîne de caractères dans laquelle la nouvelle sous-chaîne sera insérée.
 * @param index - L'index à partir duquel la nouvelle sous-chaîne sera insérée.
 * @param newStr - La nouvelle sous-chaîne à insérer dans 'str'.
 *
 * @returns Une nouvelle chaîne de caractères résultant de l'insertion de 'newStr' dans 'str' à l'index spécifié.
 */
export function insertAt(str: string, index: number, newStr: string) {
  return str.substring(0, index) + newStr + str.substring(index);
}
/**
 * Cette fonction remplace une portion de la chaîne 'str' à l'index spécifié par 'replacement'.
 *
 * @param str - La chaîne de caractères d'origine dans laquelle la modification sera effectuée.
 * @param index - L'index à partir duquel la modification sera appliquée.
 * @param replacement - La chaîne de remplacement qui sera insérée à l'index spécifié.
 *
 * @returns Une nouvelle chaîne de caractères résultant de la modification de 'str' avec 'replacement'.
 */
export function replaceAt(str: string, index: number, replacement: string) {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + replacement + str.substring(index + replacement.length);
}


