export type Groupe = "jour" | "mois" | "annee";

/**
 * Etat de saisie d'un groupe de caractères (jour, mois, année) dans un champ de saisie de date.
 */
export type EtatSaisieGroupe =
//Le groupe est vide
  { kind: "vide" }
  //Le groupe est incomplet (il manque des caractères)
  | { kind: "incomplet"; valeur: string }
  //Le groupe est complet (il contient le nombre de caractères attendus)
  | { kind: "complet"; valeur: string };

/**
 * Détermine le groupe dans lequel le curseur est positionné, à partir de la position du curseur et de la valeur textuelle du champ de saisie
 * @param valeurTextuelle valeur textuelle du champ de saisie (une date complète ou partielle, ex. "24/11/2023" , "24/1" etc.)
 * @param positionCurseur position du curseur dans le champ de saisie
 */
export function determinerGroupeSelectionne(valeurTextuelle: string, positionCurseur: number): Groupe {
  const nombreSlashAvantCurseur =
    valeurTextuelle == "" ? 0 : valeurTextuelle.substring(0,positionCurseur).replace(/[^/]/g, "").length;

  return valeurTextuelle == ""
    ? "jour"
    : nombreSlashAvantCurseur >= 2
      ? "annee"
      : nombreSlashAvantCurseur == 1
        ? "mois"
        : "jour";
}

