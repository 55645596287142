export function getLibelleCorrespondantACodeMotif(codeMotif: string | null) {
  switch (codeMotif) {
    case 'Etudes':
      return $localize`:@@libelleCorrespondantACodeMotif.Etudes:Séjour études`;
    case 'Stages':
      return $localize`:@@libelleCorrespondantACodeMotif.Stages:Séjour stage`;
    case 'PVT':
      return $localize`:@@libelleCorrespondantACodeMotif.PVT:Séjour PVT`;
    default:
      return $localize`:@@libelleCorrespondantACodeMotif.Défaut:Séjour à l'étranger`;
  }
}
