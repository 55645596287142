import { EtatSaisieDateCoherent } from "./EtatSaisieDate";

export interface BornesSaisieDate {
  jour: { indexDebut: number; indexFin: number };
  mois: { indexDebut: number; indexFin: number };
  annee: { indexDebut: number; indexFin: number };
}

/**
 * Cette fonction prend un objet représentant l'état de saisie d'une date et détermine les bornes
 * (indices de début et de fin) pour chaque groupe de caractères (jour, mois, année) dans cet état.
 * Elle renvoie un objet BornesSaisieDate contenant ces bornes.
 *
 * @param etat - L'objet de type EtatSaisieDate représentant la saisie de la date.
 *
 * @returns Un objet BornesSaisieDate contenant les indices de chaque groupe de caractères (jour, mois, année).
 * Si tous les groupes sont vides, la fonction renvoie null.
 */
export function getBornesEtat(etat: EtatSaisieDateCoherent): BornesSaisieDate | null {
  let currentIndex = 0;
  const { jour, mois, annee } = etat;
  const groupesOrdonnes = [jour, mois, annee];
  if (groupesOrdonnes.every((c) => c.kind == "vide")) return null;

  return {
    jour: { indexDebut: currentIndex, indexFin: (currentIndex += etat.jour.kind == "incomplet" ? 0 : 1) },
    mois: { indexDebut: (currentIndex += 2), indexFin: (currentIndex += etat.mois.kind == "incomplet" ? 0 : 1) },
    annee: { indexDebut: (currentIndex += 2), indexFin: (currentIndex += 3) }
  };
}
