import {InjectionToken} from "@angular/core";
import {Subject} from "rxjs";

export const IS_MOBILE$ = new InjectionToken<Subject<string>>('IS_MOBILE$');

export const isUserUsingMobile = () => {

// User agent string method
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

// Screen resolution method
  if (!isMobile) {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const hasCoarsePointer = window.matchMedia("(any-pointer:coarse)").matches;

    isMobile = hasCoarsePointer && (screenWidth < 768 || screenHeight < 768);
  }

// Touch events method
  if (!isMobile) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isMobile = (('ontouchstart' in window) && ((navigator.maxTouchPoints > 0) || ((navigator as any).msMaxTouchPoints > 0)));
  }

// CSS media queries method
  if (!isMobile) {
    const bodyElement = document.getElementsByTagName('body')[0];
    isMobile = window.getComputedStyle(bodyElement).getPropertyValue('content').indexOf('mobile') !== -1;
  }

  return isMobile

}
