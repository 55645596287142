import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  InjectionToken,
  Input, Optional,
  Output
} from '@angular/core';
import { DateFilterFn, MatDatepickerInputEvent, MatDatepickerModule } from "@angular/material/datepicker";
import { DateTime } from "luxon";
import { MatInputModule } from "@angular/material/input";
import {
  calculerCoherenceLuxonDate,
  calculerEtatEtPosition,
  EtatSaisieDate,
  formaterEtatSaisieDatePourAffichage,
  initialiserEtatSaisieDateCoherentVide
} from "@core/material-ui/components/saisie-date/model/EtatSaisieDate";
import { FormsModule, NgControl } from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { Subject } from "rxjs";

export interface SaisieDateDefaultOptions {
  placeholder?: string,
}

export const CORE_SAISIE_DATE_DEFAULT_OPTIONS = new InjectionToken<SaisieDateDefaultOptions>('CORE_SAISIE_DATE_DEFAULT_OPTIONS');


@Component({
  selector: 'core-saisie-date',
  standalone: true,
  imports: [
    MatDatepickerModule,
    MatInputModule,
    FormsModule
  ],
  providers: [{ provide: MatFormFieldControl, useExisting: SaisieDateComponent }],
  templateUrl: './saisie-date.component.html',
  styleUrl: './saisie-date.component.scss'
})
export class SaisieDateComponent implements MatFormFieldControl<DateTime | null> {
  @Input()
  set value(value: DateTime | null) {
    this.setInputDateValue(value);
  }

  get value(): DateTime | null {
    return this.inputDateValue;
  }

  stateChanges = new Subject<void>();
  static nextId = 0;

  @HostBinding() id = `core-saisie-date-${SaisieDateComponent.nextId++}`;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  private _placeholder: string = '';

  ngControl: NgControl | null = null;
  focused = false;


  @Input() filtreDate: DateFilterFn<DateTime | null> =
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_: DateTime | null) => true;
  @Input() startAtDate: DateTime | null = null;
  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';

  @Output() utilisateurAModifieDate = new EventEmitter<DateTime | null>();
  @Output() utilisateurAInteragisAvecDate = new EventEmitter<'dirty' | 'touched'>();

  private etatSaisieDate: EtatSaisieDate;
  protected inputDateValue: DateTime | null = null;

  constructor(private elementRef: ElementRef, @Inject(CORE_SAISIE_DATE_DEFAULT_OPTIONS) @Optional() private defaultOptions?: SaisieDateDefaultOptions) {
    this.etatSaisieDate = initialiserEtatSaisieDateCoherentVide();
    this.placeholder = defaultOptions?.placeholder ?? '';
  }

  setInputDateValue(dateInput: DateTime | null): void {
    this.etatSaisieDate = calculerCoherenceLuxonDate(dateInput);
    this.inputDateValue = dateInput;
    this.stateChanges.next();
  }

  private inputValueAuFocusIn: string | null = null;

  @HostListener("focusin", ["$event"])
  utilisateurEstRentrerDansLeChamp(event: FocusEvent): void {
    const input = event.target as HTMLInputElement;
    if (input.tagName === 'INPUT') {
      if (this.etatSaisieDate.kind === "incoherent") {
        this.etatSaisieDate = initialiserEtatSaisieDateCoherentVide();
        input.value = formaterEtatSaisieDatePourAffichage(this.etatSaisieDate);
      }
      this.inputValueAuFocusIn = input.value;
      this.focused = true;
      this.stateChanges.next();
    }
  }

  @HostListener("beforeinput", ["$event"])
  utilisateurVientDeSaisirUnCaractere(event: InputEvent): void {
    if (this.etatSaisieDate.kind === "coherent") {
      const input: HTMLInputElement = event.target as HTMLInputElement;
      const caractereEnCoursDeSaisi = event.data!;
      const positionDebutSelection = input.selectionStart!;
      const positionFinSelection = input.selectionEnd!;
      const inputType = event.inputType;
      const chiffresOuSeparateurUniquement = /^[\d/\\-]+$/; // N'autorise que les chiffres, ou éventellement les slashs ou tirets
      if (
        !chiffresOuSeparateurUniquement.test(caractereEnCoursDeSaisi ?? '') &&
        event.inputType !== "deleteContentBackward" &&
        event.inputType !== "insertFromPaste" &&
        event.inputType !== "deleteContentForward"
      ) {
        event.preventDefault();
        return;
      }

      const [nouvelEtat, nouvellePosition] = calculerEtatEtPosition(
        this.etatSaisieDate,
        positionDebutSelection,
        positionFinSelection,
        caractereEnCoursDeSaisi,
        inputType,
      );
      this.etatSaisieDate = nouvelEtat;

      if (this.etatSaisieDate.kind === "incoherent") {
        this.etatSaisieDate = calculerCoherenceLuxonDate(DateTime.fromFormat(this.etatSaisieDate.valeur, 'dd/MM/yyyy'));
      }

      if (this.etatSaisieDate.kind === "coherent") {
        input.value = formaterEtatSaisieDatePourAffichage(this.etatSaisieDate);
        input.setSelectionRange(nouvellePosition, nouvellePosition);
        event.preventDefault();
      }
    }
    this.stateChanges.next();

  }

  @HostListener("input", ["$event"])
  utilisateurATermineLaSaisieDuCaractere(event: InputEvent): void {
    this.etatSaisieDate = calculerCoherenceLuxonDate(DateTime.fromFormat((event.target as HTMLInputElement).value, 'dd/MM/yyyy'));
    this.stateChanges.next();

  }

  @HostListener("focusout", ["$event"])
  utilisateurEstSortiDuChamp(event: FocusEvent): void {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    if (input.tagName === 'INPUT') {
      let date: DateTime | null = null;
      let valeurAttendueDansChamps = input.value;

      if (this.etatSaisieDate.kind === "coherent") {
        const dateFormatee = formaterEtatSaisieDatePourAffichage(this.etatSaisieDate);
        if (dateFormatee != '') {
          date = DateTime.fromFormat(dateFormatee, 'dd/MM/yyyy');
        valeurAttendueDansChamps = dateFormatee;

        }
      }

      if(this.inputValueAuFocusIn != valeurAttendueDansChamps){
        this.utilisateurAModifieDate.emit(date);
        this.utilisateurAInteragisAvecDate.emit('dirty');
      }
      else{
        this.utilisateurAInteragisAvecDate.emit('touched' );
      }
    }
    this.focused = false;
    this.stateChanges.next();
  }

  get empty() {
    return this.etatSaisieDate.kind == 'coherent' && this.etatSaisieDate.annee.kind == 'vide' && this.etatSaisieDate.mois.kind == 'vide' && this.etatSaisieDate.jour.kind == 'vide';
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req: boolean) {
    this._required = req;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    this.stateChanges.next();
  }


  private _disabled = false;

  get errorState(): boolean {
    return false;
  }
  controlType = 'core-saisie-date';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDescribedByIds(ids: string[]): void {
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element)?.tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input').focus();
    }
  }

  onDatePicked($event: MatDatepickerInputEvent<DateTime, DateTime | null>) {
    this.inputDateValue = $event.value;
    this.etatSaisieDate = calculerCoherenceLuxonDate($event.value);
    this.utilisateurAModifieDate.emit($event.value);
    this.utilisateurAInteragisAvecDate.emit('dirty');
    this.stateChanges.next();
  }
}

